import DisclaimerBase from "./DisclaimerBase"

function WorkInProgress(prop) {
    return (
        DisclaimerBase(
            {
                s: "Work in Progress Disclaimer:",
                p: "This page is a work in progress",
            }
        )
    )
}

export default WorkInProgress