import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import GithubLink from "../components/GithubLink"
import WorkInProgress from "../components/WorkInProgress"

const AdventOfCode = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>Advent of Code</h1> 
            {GithubLink("https://github.com/Gautreaux/AOC-Python")}
            <p>Advent of Code (<a href="https://adventofcode.com">Link</a>) is a puzzle series during December. Each day a new, two-part puzzle is released at midnight eastern time. Participants compete for points based on fastest time to a correct solution. The problems start easy and slowly build in complexity, sometimes reusing components from one day in a latter day.</p>
            <p>My solutions are available on <a href="https://github.com/Gautreaux/AOC-Python">Github</a></p>
            <p>The goal is to eventually have a solution to each problem. As of writing I have completed only about half of the available points (from 2015 to 2021) and a perfect 50/50 only in 2020.</p>
            {WorkInProgress()}
            <p>TODO: List of favorite or most interesting problems / days</p>
            {FooterBar()}
        </div>
    )
}

export default AdventOfCode